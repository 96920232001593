const job = [
    {
        title: 'Full Stack Web Developer',
        description: 'We are looking for a Full Stack Web Developer with 4-6 years experience as a web developer in the latest of technologies. As a Full Stack Developer, you will be responsible for designing and implementing web applications that deliver a seamless user experience. You will work on both the front-end and back-end development, ensuring the entire application functions effectively',
        skills: `Developed and maintained scalable user interface interfaces for existing and new web pages using HTML5, CSS, and JavaScript frameworks like jQuery, ReactJS, NodeJS.
Working experience of 3 years minimum with the ReactJS and Redux frameworks is necessary.
An ability to write code for responsive UI.
Must have good knowledge of Git, BitBucket, and GitHub.
Self-motivated to meet deadlines while collaborating effectively as part of a team.
Strong organizational skills, including the ability to balance multiple projects.
Experience with databases and complex datasets.
Strong analytical skills and methodical problem-solving.
Ability to communicate complex technical issues to non-technical individuals.
Excellent learning new skills and staying current with rapidly changing technology
Designed, built, and maintained efficient, reusable, and reliable code.
Produce clean code.
Familiarity with testing frameworks.
Develop clients deliverables in a timely manner.
Fix bugs and deliver results to the team and clients.`,

        role: `Solid knowledge in REST API, Java Script, CSS, Type Script
Experience with SQL AND NOSQL Databse
Experience with modern web application, development, including build tools, testing and C1/CD pipeline
Experience with source control and development workflows, Bitbucket, GIT
Familiarity with API architecture including microservice strategy.
Excellent analytical and problem-solving skills, solid understanding of coding aesthetics, able to review code and provide suggestions
Experience with cloud technology including AWS, Docker, ECS/EKS
Exposure to performance and scalability improvement is desired
Must be comfortable in a fast pace , changing environment
Strong 3 + experience with Nodejs, Express and React JS
Strong Communication skills`,
        location: `
Location-Noida
Job Type: Full-time
Education:
Bachelor's (Preferred)
Experience:
Angular: 2 years (Preferred)
total work: 2 years (Preferred)
Java: 1 year (Preferred)
Work Location: In person`,
    },
    {
        title: 'Company Secretary',
        description: `Secretarial work & Statutory Compliances under Companies Act, Listing Regulations and SEBI Regulations`,
        skills:`
Knowledge/experience of secretarial matters of overseas subsidiaries shall be an added advantage`,
        role: `
Prepare and file necessary documents, returns, and forms with government authorities, such as the Registrar of Companies (RoC), MCA and the Securities and Exchange Board of India (SEBI).

Liaison with ROC, Stock Exchange, NSDL/CDSL, SEBI and other authorities

Handling of Statutory and Secretarial Audit related work

Maintain and update statutory registers, books, and records required by law.

Monitor changes in relevant legislation and regulations and communicate their impact on the company.

Organizing Board and other committee Meetings and Shareholder's General Meetings of the Company, including preparing agendas, minutes, and resolutions

Prepare and file annual reports and other corporate disclosures with RoC, Stock Exchanges etc.

Handling of matters related to corporate restructuring, mergers, acquisitions, and other corporate transactions.

RBI compliances for inward/outward investments/financial commitments`,
         location: `Location-Noida, 

Job Type: Full-time`

    },
    {
        title: 'Charted Accountant',
        description: `We are looking for skilled Charted Accountant. In the role you will be responsible for managing and oversee the daily operations of the Finance Department.`,
        skills:`1.) Advanced computer skills on MS Office, accounting software and databases.

2.) High integrity - fair, honest, respectful of others, with a caring attitude about the future of the organization; dedicated to high standard of professionalism, and committed to continuing improvement.

3.) Qualified Chartered Accountant with atleast 3 years of work experience.

4.) Knowledge of RBI Compliances and other statutory laws.`,
        role: `In the role you will be responsible for managing and oversee the daily operations of the Finance Department including:

1.) Interacting with financial institutions

2.) Timely Compliance

3.) Month, quarter and year end financial reports

4.) Raising funds for the Company`,
         location: `Bachelor's (Preferred)
Experience:

Accounting: 2 years (Preferred)
Tally: 2 years (Preferred)
total work: 2 years (Preferred)
License/Certification:

CA-Inter (Preferred)
Work Location: In person`

    },
    {
        title: 'Executive Assistant',
        description: `Here is an exciting Opportunity to be an Executive Assistant with an E-commerce firm where you will get to partner with the company's leadership team to drive a systematic approach for running the organization and partnering on the execution of the overall strategy by building relevant planning models, in-depth tracking frameworks and solving for select business problems.`,
        skills:`Executive Secretary is to assist the General Manager cadre officials in day to day business activities in the organization.

~Strong interpersonal skills and the ability to build relationships with stakeholders

~(Good ppt and excel working knowledge)

~Strong organizational skills that reflect ability to perform and prioritize multiple tasks seamlessly with excellent attention to detail.

~Excellent management, time-management, and problem-solving. skills.

~Excellent written communication skill Experience of reading and drafting agreements, letters etc.
`,
        role: `~ Preparing daily calendar of MD. Ability to independently manage the office and correspond with the clients [business letters] and within the Company.

~Organizing internal and external meetings, events and teleconferences, including booking venues, arranging accommodation and taking minutes as required.

~Internal and external communication related to MD's office.

~Maintain confidentially and privacy at all times in respect of these and any other communications which are necessary and appropriate for the discharge of the role and responsibilities.

~Will be required to coordinate events at various places & do field & research work.
`,
         location: `Job Type: Full-time

Education:

Bachelor's (Preferred)
Experience:

total work: 2 years (Preferred)
Language:

English (Preferred)
Work Location: In person`

    },
    {
        title:'Sales and Marketing Executive - Real Estate',
        description:'The Sales and Marketing Executive is responsible for generating leads, nurturing client relationships, and executing marketing strategies to promote real estate properties and services. This role requires a deep understanding of the real estate market, strong sales skills, and the ability to craft compelling marketing campaigns to attract potential buyers and investors.',
        skills:`- Bachelor's degree in Marketing, Business, Real Estate, or a related field.

- Proven experience in sales and marketing roles, preferably in the real estate industry.

- Strong understanding of real estate market dynamics and property sales processes.

- Excellent communication, negotiation, and interpersonal skills.

- Proficiency in MS Office, CRM software, and digital marketing tools.

- Ability to work independently and as part of a team.

- Strong analytical and problem-solving skills.

- Creative thinking and innovative approach.

Preferred Skills:

- Experience with property management software and MLS (Multiple Listing Service).

- Knowledge of local real estate laws and regulations.

- Ability to manage multiple projects and meet deadlines.

Physical Requirements

- Ability to travel to property sites and client meetings as needed.

- Must be able to work flexible hours, including some evenings and weekends.`,
        role:`1. Sales and Lead Generation:

- Identify and pursue new sales opportunities and leads.

- Conduct property tours and presentations to prospective clients.

- Develop and maintain relationships with clients, real estate agents, and other industry professionals.

- Negotiate and close sales deals to meet or exceed sales targets.

2. Marketing Strategy and Execution:

- Develop and implement marketing plans and campaigns for real estate properties.

- Create promotional materials, including brochures, flyers, and online content.

- Utilize digital marketing tools and platforms to reach a broader audience.

- Organize and participate in open houses, property exhibitions, and other promotional events.

3. *Market Research and Analysis:

- Conduct market research to identify trends, opportunities, and competition.

- Analyze market data to inform sales and marketing strategies.

- Provide regular reports on market conditions, sales performance, and marketing campaign effectiveness.

4. Client Management:

- Provide exceptional customer service to clients throughout the buying process.

- Address client inquiries and concerns promptly and professionally.

- Guide clients through the legal and financial aspects of property transactions.

5. *Collaboration:

- Work closely with the sales and marketing teams to align strategies and achieve business objectives.

- Coordinate with other departments, such as finance and legal, to ensure seamless transactions.

- Collaborate with external partners, such as advertising agencies and media outlets.`,
        location:`
       Job Type: Full-time
        Location:[Noida 63]

Department: Sales and Marketing
total work: 2 years (Preferred)
Reports to: Sales Manager/Marketing Manager

Job Type: Full-Time`
    }
];

export default job;