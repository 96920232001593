const data1 = [
    {
        label: "Media",
        value: "Media",
        images: [
          {
            imageLink:
            "https://lh3.googleusercontent.com/p/AF1QipMzQ8T4MMXChpWZrOfI-tgpZjz4bM63wrzVnYN9=s1360-w1360-h1020" 
          },
          {
            imageLink:
            "https://lh3.googleusercontent.com/p/AF1QipPIkmlo--IOwxrY0xjRVbAdFzbuFRoQxxhifTTU=s1360-w1360-h1020" 
          },
          {
            imageLink:
            "https://lh3.googleusercontent.com/p/AF1QipMCh5ETjC85EhtSmkGBv_Zlp7QOPXDNufqxh1GT=s1360-w1360-h1020" 
          }
        ],
      },
    {
      label: "Food & Beverages",
      value: "html",
      images: [
        {
          imageLink:
          "https://badshahbiryani.in/wp-content/uploads/2024/06/origin-of-biryani.png" 
        },
        {
          imageLink:
          "https://badshahbiryani.in/wp-content/uploads/2024/06/badhsha-biryani.png" 
        },
        {
          imageLink:
          "https://badshahbiryani.in/wp-content/uploads/2024/05/Chicken-Hyderabadi-Dum-Biryani-1.jpg" 
        },
        {
          imageLink:
          "https://badshahbiryani.in/wp-content/uploads/2024/05/Egg-Dum-Biryani.jpg"
        },
      ],
    },
    {
        label: "Media Distribution",
        value: "Media Distribution",
        images: [
          {
            imageLink:
            "https://devdigital.in/static/media/pexels-cottonbro-4009409.f4e8e6fd5dcfc48900a6.jpg" 
          },
          {
            imageLink:
            "https://devdigital.in/static/media/pexels-hillaryfox-1595385.da62dfc0294fcd572b39.jpg" 
          },
          {
            imageLink:
            "https://devdigital.in/static/media/pexels-olly-853151.8ab684f5d6926e1c557e.jpg" 
          },
          {
            imageLink:
            "https://devdigital.in/static/media/tv-select.0e2f0fc30c4d97310bba.jpg"  
          },
       
        ],
      },
     
      {
        label: "Construction",
        value: "Construction",
        images: [
          {
            imageLink:
            "https://t4.ftcdn.net/jpg/00/68/63/23/360_F_68632352_kmHLwFc2rQLmnKqn6gM0bhOPqxRTx8sY.jpg"  
          },
        ],
      },
      {
        label: "HealthCare",
        value: "HealthCare",
        images: [
          {
            imageLink:
            "https://res.cloudinary.com/dvahmkxd9/image/upload/v1722924290/Aunherbal/wujhfnhbsvb4wkaeglbr.png"
          },
          {
            imageLink:
           "https://res.cloudinary.com/dvahmkxd9/image/upload/v1716454968/Aunherbal/dgzopmqpfilqhwnygav4.png"
          },
          {
            imageLink:
            "https://res.cloudinary.com/dvahmkxd9/image/upload/v1716455005/Aunherbal/rcdzrjjm6alohigch3js.png"
          },
          {
            imageLink:
           
            "https://res.cloudinary.com/dvahmkxd9/image/upload/v1716455042/Aunherbal/fxkwhp2ywh36n1aqpg9i.png"
          },
          {
            imageLink:
             "https://res.cloudinary.com/dvahmkxd9/image/upload/v1716455080/Aunherbal/nordt6fyo8ygovaq0584.png"
          },
    
        ],
      },
      {
        label: "IT",
        value: "IT",
        images: [
          {
            imageLink:
            "https://t4.ftcdn.net/jpg/04/78/95/73/360_F_478957385_zCuEGTXNJKPygVCxmxkY01oV7JFVUFDv.jpg"  
          },
          {
            imageLink:
            "https://t3.ftcdn.net/jpg/03/18/14/08/360_F_318140882_seYmbowNlJMFQqoBGxULHUiIVB92uz0c.jpg"
          },
      
        ],
      },
      {
        label: "Renewable Energy",
        value: "Renewable Energy",
        images: [
          {
            imageLink:
            "https://eitherview.com/wp-content/uploads/2023/05/biogas-industry-illustration.jpg" 
          },
          {
            imageLink:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNyjOe63_uBquJjlRWmM8mmCvjUtsQQq26aQ&s" 
          },
        ],
      },
      {
        label: "Real Estate",
        value: "Real Estate",
        images: [
          {
            imageLink:
            "https://cdn.corporatefinanceinstitute.com/assets/real-estate.jpeg"  
          },
          {
            imageLink:
           "https://img.indiafilings.com/learn/wp-content/uploads/2015/10/12011006/Real-Estate-Agent-Business-India.jpg"
          },    
        ],
      },
  ];

  export default data1;